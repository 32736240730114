<template>
  <div>
    <b-card>
      <validation-observer
        ref="form"
        v-slot="{invalid}"
      >
        <b-form
          novalidate
          @submit.prevent="onSubmit"
        >
          <b-row>
            <!-- name -->
            <b-col cols="6">
              <LocaleTabs
                :errors="formErrors"
                name="name"
              >
                <template
                  v-for="(i,k,idx) in languages"
                  :slot="`lang${idx}`"
                  slot-scope="slotScope"
                >
                  <ValidationProvider
                    :key="idx"
                    v-slot="{ errors }"
                    :class="slotScope.selectedTab!==idx?'hidden':''"
                    :vid="`name.${k}`"
                    :name="`${i} Name`"
                    rules="required"
                  >
                    <b-form-group :label="`${i} Name`">
                      <b-form-input
                        v-model="form.name[k]"
                        :placeholder="`${i} Name`"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </ValidationProvider>
                </template>
              </LocaleTabs>
            </b-col>
            <b-col cols="6">
              <LocaleTabs
                :errors="formErrors"
                name="Button Text"
              >
                <template
                  v-for="(i,k,idx) in languages"
                  :slot="`lang${idx}`"
                  slot-scope="slotScope"
                >
                  <ValidationProvider
                    :key="idx"
                    v-slot="{ errors }"
                    :class="slotScope.selectedTab!==idx?'hidden':''"
                    :vid="`button_text.${k}`"
                    :name="`${i} Button Text`"
                  >
                    <b-form-group :label="`${i} Button Text`">
                      <b-form-input
                        v-model="form.button_text[k]"
                        :placeholder="`${i} Button Text`"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </ValidationProvider>
                </template>
              </LocaleTabs>
            </b-col>
            <b-col cols="6">
              <validation-provider
                v-slot="{ errors }"
                name="Button Link"
                vid="button_link"
              >
                <b-form-group
                  label="Button Link"
                >
                  <b-form-input
                    v-model="form.button_link"
                    placeholder="Button Link"
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12">
              <LocaleTabs
                :errors="formErrors"
                name="description"
              >
                <template
                  v-for="(i,k,idx) in languages"
                  :slot="`lang${idx}`"
                  slot-scope="slotScope"
                >
                  <ValidationProvider
                    :key="idx"
                    v-slot="{ errors }"
                    :class="slotScope.selectedTab!==idx?'hidden':''"
                    :vid="`description.${k}`"
                    :name="`${i} Description`"
                    rules="required"
                  >
                    <b-form-group :label="`${i} Description`">
                      <b-form-textarea
                        v-model="form.description[k]"
                        :placeholder="`${i} Description`"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </ValidationProvider>
                </template>
              </LocaleTabs>
            </b-col>
            <b-col
              cols="12"
              class="my-2"
            >
              <legend tabindex="-1" class="bv-no-focus-ring col-form-label pt-0">Avatar</legend>
              <ValidationProvider
                v-slot="{ errors }"
                vid="avatar"
                name="Avatar"
              >
                <MediaLibraryAttachment
                  ref="mediaComponent"
                  :initial-value="media"
                  :max-items="1"
                  name="avatar"
                  :headers="headers"
                  routePrefix="media-library"
                  :uploadDomain="baseURL"
                  @change="getFiles($event)"
                  :validation-rules="{ accept: ['image/webp','image/png'], maxSizeInKB: 2090 }"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-col>

            <!-- checkbox -->
            <b-col cols="12">
              <b-card-text class="mb-0">
                Status
              </b-card-text>
              <b-form-checkbox
                v-model="form.status"
                checked="true"
                value="active"
                unchecked-value="inactive"
                name="check-button"
                switch
              />
            </b-col>

            <!-- submit and reset -->
            <b-col cols="12 text-right">
              <LoadingButton />
              <b-button
                type="reset"
                variant="outline-secondary"
              >
                Reset
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>
<script>
import formMixin from '@/mixins/formMixin'
// eslint-disable-next-line import/no-extraneous-dependencies
import { serialize } from 'object-to-formdata'
import _ from 'lodash'
import { baseURL } from '@/services/url.service'
import MediaLibraryAttachment from '../../components/media-spatie/media-library-pro-vue2-attachment/src/MediaLibraryAttachment.vue'

export default {
  components: {
    MediaLibraryAttachment,
  },
  mixins: [formMixin],
  data() {
    return {
      imageAfterUpload: [],
      baseURL: baseURL(),
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      media: null,
      avatar_uuid: null,
      validationErrors: {
        avatar: 'The Avatar field is required',
      },
      form: {
        name: {
          en: null,
          ar: null,
        },
        description: {
          en: null,
          ar: null,
        },
        button_text: {
          en: null,
          ar: null,
        },
        button_link: null,
        avatar_uuid: null,
        status: 'active',
        type: 'image',
      },
      form_data: null,
    }
  },
  watch: {
    image(val) {
      if (val) {
        this.form.image = val
      } else {
        this.form.image = 'delete'
      }
    },
  },
  created() {
    if (this.isEdit) {
      this.loadData()
    }
  },
  methods: {
    getFiles(e) {
      const arr = []
      arr.push(e)
      this.imageAfterUpload = arr
      this.form.avatar_uuid = Object.keys(this.imageAfterUpload[0])[0]
    },
    loadData() {
      this.axios.get(`/${this.resource}/${this.resourceId}`)
        .then(res => {
          const { data } = res.data
          this.form = {
            name: data.name_value,
            description: data.description_value,
            button_text: _.isArray(data.button_text_value) ? {
              en: null,
              ar: null,
            } : data.button_text_value,
            button_link: data.button_link,
            type: 'image',
            status: data.status,
            avatar_uuid: data.media?.uuid || null,
          }
          this.media = data.media
          this.media = data.media
          this.avatar_uuid = data.media?.uuid || null
          let arr = []
          const obj = {
            attributes: this.media,
            clientValidationErrors: [],
            client_preview: data.media?.original_url,
            upload: {},
          }
          arr.push(obj)
          this.$refs.mediaComponent.mediaLibrary.changeState(state => {
            state.media = arr
          })
        })
    },
    loadFormData() {
      const $form = _.cloneDeep(this.form)
      if ($form.button_text) {
        Object.keys($form.button_text)
          .forEach(key => {
            $form.button_text[key] = $form.button_text[key] || null
          })
      }
      this.form_data = $form
      if (this.isEdit) {
        if (!($form.image instanceof File) && $form.image !== 'delete') {
          delete $form.image
        }
        // eslint-disable-next-line no-unused-vars
        this.form_data = {
          ...$form,
          _method: 'put',
        }
      }
      return $form
    },
    serializeForm() {
      return serialize(this.form_data, {
        indices: true,
        nullsAsUndefineds: true,
      })
    },
  },
}
</script>
